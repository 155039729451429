import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import Select from "react-select";
import {
  TextField,
  Button,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Switch,
  IconButton,
} from "@mui/material";
import { ArrowBack } from "@mui/icons-material";
import { decode } from "../helper";

function ChannelPage() {
  const { channelId } = useParams();
  const initialChannel = {
    id: -1,
    title: "",
    category_id: -1,
    channel_title: "",
    position: 1,
    published: true,
    thumbnail: "",
  };
  const [channelData, setChannelData] = useState({
    channel: initialChannel,
    links: [],
    categories: [],
  });
  const [deletedLinks, setDeletedLinks] = useState({ ids: [] });
  const [linkDetails, setLinkDetails] = useState({
    title: "",
    position: 1,
    drm: "",
    type: "",
    url: "",
  });
  const navigate = useNavigate();

  useEffect(() => {
    axios
      .get(`/channel_by_id?id=${channelId}`, {
        withCredentials: true,
      })
      .then((res) => {
        if (res.data != null) {
          setChannelData({
            channel: res.data[0] || initialChannel,
            links: res.data[1],
            categories: res.data[2],
          });
        }
      });
  }, [channelId]);

  const saveChannelChanges = () => {
    const action = channelId === "new" ? "add_channel" : "update_channel";
    axios
      .post(
        `http://localhost:5000/${action}`,
        {
          channelData: channelData,
          deletedIds: deletedLinks,
        },
        {
          withCredentials: true,
        }
      )
      .then((res) => {
        if (res.data === "success") {
          navigate(-1);
        } else {
          console.log(res.data);
        }
      });
  };

  const removeLink = (id) => {
    let array = channelData.links;
    const newList = array.filter((item) => item.id !== id);

    if (id !== -1) {
      setChannelData({
        ...channelData,
        links: newList,
      });
      id &&
        setDeletedLinks({
          ...deletedLinks,
          ids: [...deletedLinks.ids, id],
        });
    }
  };

  return (
    <>
      <div className="ml-80  flex flex-col relative">
        <div className="w-full h-full pt-10 pl-10">
          <div className="flex items-center">
            <IconButton onClick={() => navigate(-1)}>
              <ArrowBack />
            </IconButton>
            <div>
              {" "}
              {channelId === "new" ? (
                <h3 className="font-bold pb-5">Add Channel</h3>
              ) : (
                <h3 className="font-bold pb-5">
                  Edit Channel
                  <font className="maincolor bold">#{channelId}</font>
                </h3>
              )}
            </div>
          </div>
          <div className="inputGroup">
            <Box className="space-y-5" component="form">
              <div className="flex space-x-2 items-center">
                <TextField
                  label="Title"
                  value={channelData.channel ? channelData.channel.title : ""}
                  onChange={(e) =>
                    setChannelData({
                      ...channelData,
                      channel: {
                        ...channelData.channel,
                        title: e.target.value,
                      },
                    })
                  }
                />

                <TextField
                  id="outlined-number"
                  label="Channel Position"
                  type="number"
                  value={
                    channelData?.channel ? channelData.channel.position : 1
                  }
                  onChange={(e) =>
                    setChannelData({
                      ...channelData,
                      channel: {
                        ...channelData.channel,
                        position: e.target.value,
                      },
                    })
                  }
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </div>

              <div className="flex space-x-2 items-center">
                <TextField
                  label="Thumbnail"
                  value={
                    channelData.channel ? channelData.channel.thumbnail : ""
                  }
                  onChange={(e) =>
                    setChannelData({
                      ...channelData,
                      channel: {
                        ...channelData.channel,
                        thumbnail: e.target.value,
                      },
                    })
                  }
                />
                <img
                  className="h-10"
                  src={channelData.channel ? channelData.channel.thumbnail : ""}
                />
              </div>
              <div className="flex space-x-6 items-center">
                <p>Category</p>
                {console.log(channelData)}

                <Select
                  value={{
                    label: channelData?.channel?.category_title,
                    value: channelData?.categories
                      ? channelData.category_id
                      : -1,
                  }}
                  onChange={(e) => {
                    console.log(e);

                    setChannelData({
                      ...channelData,
                      channel: {
                        ...channelData.channel,
                        category_id: e.value,
                        category_title: e.label,
                      },
                    });
                  }}
                  options={
                    channelData?.categories
                      ? channelData.categories.map((category) => ({
                          label: category.title,
                          value: category.id,
                        }))
                      : []
                  }
                />
              </div>

              <div className="flex space-x-6 items-center">
                <div className="flex items-center">
                  <p>Published</p>
                  <Switch
                    label="Published"
                    checked={
                      channelData?.channel
                        ? channelData.channel.published
                        : false
                    }
                    onChange={(event) => {
                      setChannelData({
                        ...channelData,
                        channel: {
                          ...channelData.channel,
                          published: event.target.checked,
                        },
                      });
                    }}
                  />
                </div>
              </div>

              <div className="space-y-3 mr-20">
                <h1 className="font-bold">Links</h1>
                <div>
                  <TableContainer component={Paper}>
                    <Table>
                      <TableHead>
                        <TableRow key={"1"}>
                          <TableCell align="center">Title</TableCell>
                          <TableCell align="center">Position</TableCell>
                          <TableCell align="center">Type</TableCell>
                          <TableCell align="center">DRM</TableCell>
                          <TableCell align="center">URL</TableCell>
                          <TableCell align="center">Action</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {channelData.links?.map((link) => (
                          <TableRow key={link.id}>
                            <TableCell align="center">{link.title}</TableCell>
                            <TableCell align="center">
                              {link.position}
                            </TableCell>
                            <TableCell align="center">{link.type}</TableCell>
                            <TableCell align="center">{decode(link.drm)}</TableCell>
                            <TableCell align="center">{decode(link.url)}</TableCell>
                            <TableCell align="center">
                              <Button onClick={() => removeLink(link.id)}>
                                Delete
                              </Button>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
                <h1 className="font-bold">Add More</h1>
                <div className="flex space-x-3">
                  <TextField
                    label="Title"
                    value={linkDetails.title}
                    onChange={(e) =>
                      setLinkDetails({
                        ...linkDetails,
                        title: e.target.value,
                      })
                    }
                  />
                  <TextField
                    id="outlined-number"
                    label="Position"
                    type="number"
                    value={linkDetails.position}
                    onChange={(e) =>
                      setLinkDetails({
                        ...linkDetails,
                        position: Number(e.target.value),
                      })
                    }
                  />
                  <TextField
                    label="Type"
                    value={linkDetails.type}
                    onChange={(e) =>
                      setLinkDetails({
                        ...linkDetails,
                        type: e.target.value,
                      })
                    }
                  />
                  <TextField
                    label="Drm"
                    value={linkDetails.drm}
                    onChange={(e) =>
                      setLinkDetails({
                        ...linkDetails,
                        drm: e.target.value,
                      })
                    }
                  />
                </div>
                <TextField
                  label="Url"
                  value={linkDetails.url}
                  onChange={(e) =>
                    setLinkDetails({
                      ...linkDetails,
                      url: e.target.value,
                    })
                  }
                  style={{ width: 874 }}
                />
                <div className="">
                  <Button
                    onClick={() => {
                      setChannelData({
                        ...channelData,
                        links: [...channelData.links, linkDetails],
                      });

                      setLinkDetails({
                        title: "",
                        position: 1,
                        drm: "",
                        type: "",
                        url: "",
                      });
                    }}
                  >
                    Add Link
                  </Button>
                </div>
              </div>
              <div className="w-96 grid justify-items-center">
                <Button
                  variant="contained"
                  onClick={() => saveChannelChanges()}
                >
                  Save
                </Button>
              </div>
            </Box>
          </div>
        </div>
      </div>
    </>
  );
}

export default ChannelPage;
