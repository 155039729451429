import React from 'react';
import ReactDOM from 'react-dom/client';
import axios from 'axios';
import './input.css';
import './output.css';
import App from './App';
import registerServiceWorker from './registerServiceWorker';
import { AuthLogin } from './AuthComponents/AuthLogin';

axios.defaults.baseURL ='http://localhost:5000'

const root = ReactDOM.createRoot(document.getElementById("root"))

root.render(
  <AuthLogin>
    <App />
  </AuthLogin>
    );
registerServiceWorker();
