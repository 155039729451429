import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Homepage from "./Pages/Homepage";
import Login from "./Pages/Login";
import "./Pages/Styles/order.css";
import AdminPanel from "./Pages/AdminPanel";
import LoginRoute from "./AuthComponents/LoginRoute";
import AdminRoute from "./AuthComponents/AdminRoute";
import Sidebar from "./Components/Sidebar";
import Lives from "./Pages/lives";
import LivePage from "./Pages/LivePage";
import Categories from "./Pages/categories";
import CategoryPage from "./Pages/CategoryPage";
import Channels from "./Pages/channels";
import ChannelPage from "./Pages/ChannelPage";
import Config from "./Pages/config";
import NotificationPage from "./Pages/NotificationPage";

function App() {
  return (
    <BrowserRouter>
      <Sidebar></Sidebar>
      <Routes>
        <Route
          path="/"
          exact
          element={
              <Homepage />
          }
        />
     

        <Route path="/lives" element={<Lives />} />

        <Route path="/lives/:liveId" element={<LivePage />} />
        <Route path="/channels" element={<Channels />} />
        <Route path="/channel/:channelId" element={<ChannelPage />} />
        <Route path="/categories" element={<Categories />} />
        <Route path="/category/:categoryId" element={<CategoryPage />} />
        <Route path="/config" element={<Config />} />

 
    
        <Route
          path="/notification"
          element={
              <NotificationPage />
          }
        />
        <Route
          path="/adminPannel"
          element={
            <AdminRoute>
              <AdminPanel />
            </AdminRoute>
          }
        />
        <Route
          path="/login"
          element={
            <LoginRoute>
              <Login />
            </LoginRoute>
          }
        />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
