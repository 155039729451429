import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import {
  TextField,
  Button,
  Switch,
  IconButton,
} from "@mui/material";
import { ArrowBack } from "@mui/icons-material";

function CategoryPage() {
  const { categoryId } = useParams();
  const [categoryData, setCategoryData] = useState({
    id: -1,
    title: "",
    published: 1,
    position: 1,
    logo: "",
  });

  const [linkDetails, setLinkDetails] = useState({
    title: "",
    position: 1,
    drm: "",
    type: "",
    url: "",
  });
  const navigate = useNavigate();



  useEffect(() => {
    categoryId > 0 &&
      axios
        .get(`/category_by_id?id=${categoryId}`, {
          withCredentials: true,
        })
        .then((res) => {
          if (res.data != null) {
            console.log(res.data[1]);
            setCategoryData(res.data);
          }
        });
  }, [categoryId]);

  const saveCategoryChanges = () => {
    const action = categoryId === "new" ? "insert_category" : "update_category";
    axios
      .post(
        `/${action}`,
        {
          category: categoryData,
        },
        {
          withCredentials: true,
        }
      )
      .then((res) => {
        if (res.data === "success") {
          navigate(-1);
        } else {
          console.log(res.data);
        }
      });
  };



  return (
    <>
      <div className="ml-80  flex flex-col relative">
      
        <div className="w-full h-full pt-10 pl-10">
          <div className="flex items-center">
            <IconButton onClick={() => navigate(-1)}>
              <ArrowBack />
            </IconButton>
            <div>
              {" "}
              {categoryId === "new" ? (
                <h3 className="font-bold pb-5">Add Category</h3>
              ) : (
                <h3 className="font-bold pb-5">
                  Edit Category
                  <font className="maincolor bold">#{categoryId}</font>
                </h3>
              )}
            </div>
          </div>
          <div className="inputGroup">
            <Box className="space-y-5" component="form">
              <div className="flex space-x-2 items-center">
                <TextField
                  required
                  id="outlined-required"
                  label="Category Title"
                  value={categoryData.title}
                  onChange={(e) =>
                    setCategoryData({
                      ...categoryData,
                      title: e.target.value,
                    })
                  }
                />

                <TextField
                  id="outlined-number"
                  label="Category Position"
                  type="number"
                  value={categoryData.position}
                  onChange={(e) =>
                    setCategoryData({
                      ...categoryData,
                      position: e.target.value,
                    })
                  }
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </div>
              <TextField
                label="Logo"
                value={categoryData.logo}
                onChange={(e) =>
                  setLinkDetails({
                    ...linkDetails,
                    url: e.target.value,
                  })
                }
                style={{ width: 500 }}
              />
              <div className="flex items-center">
                <p>Published</p>
                <Switch
                  label="Published"
                  checked={categoryData.published}
                  onChange={(event) => {
                    setCategoryData({
                      ...categoryData,
                      published: event.target.checked,
                    });
                  }}
                />
              </div>
              <div className="w-96 grid justify-items-center">
                <Button variant="contained" onClick={() => saveCategoryChanges()}>
                  Save
                </Button>
              </div>
            </Box>
          </div>
        </div>
      </div>
    </>
  );
}

export default CategoryPage;
