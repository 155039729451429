import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormControlLabel,
  ImageList,
  ImageListItem,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  TextField,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import axios from "axios";

const LogoPickerDialog = (props) => {
  const { open, onClose, onClick } = props;

  const [logos, setLogos] = useState([]);
  const [logoUpdated, setLogoUpdated] = useState(false);
  const [newUrl, setNewUrl] = useState("");

  useEffect(() => {
    axios
      .get(`http://localhost:5000/logos`, {
        withCredentials: true,
      })
      .then((res) => {
        if (res.data != null) {
          setLogoUpdated(false);
          setLogos(res.data);
        }
      });
  }, [logoUpdated]);

  const saveOrderChanges = () => {
    if (newUrl) {
      axios
        .post(
          "http://localhost:5000/newlogo",
          {
            url: newUrl,
          },
          { withCredentials: true }
        )
        .then((res) => {
          if (res.data === "success") {
            setLogoUpdated(true);
          }
        });
    }
  };

  return (
    <Dialog fullWidth={true} maxWidth="md" open={open} onClose={onClose}>
      <DialogTitle>Logos</DialogTitle>
      <DialogContent>
        <div>
          <div className="flex flex-row p-5 space-x-2">
            <TextField
              label="Add New Logo"
              fullWidth
              value={newUrl}
              onChange={(e) => setNewUrl(e.target.value)}
            />
            <Button variant="contained" onClick={() => saveOrderChanges()}>
              Add
            </Button>
          </div>

          <ImageList sx={{ height: 450 }} cols={5} rowHeight={10}>
            {logos.map((item) => (
              <ImageListItem key={item.url}>
                <div
                  onClick={() => {
                    onClick(item.url);
                  }}
                >
                  <img
                    className="w[100px] object-fill"
                    srcSet={item.url}
                    src={item.url}
                    alt={item.url}
                    loading="lazy"
                  />
                </div>
              </ImageListItem>
            ))}
          </ImageList>
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};

export default LogoPickerDialog;
