
import axios from "axios";
import { AddCircleOutlineRounded, ReadMoreRounded } from "@mui/icons-material";
import Pagination from "../Components/Pagination";
import DeleteIcon from '@mui/icons-material/Delete';
import { Link, useNavigate } from "react-router-dom";
import React, { useEffect, useState }  from 'react';
import { IconButton } from "@mui/material";

function Categories(){
    

   // const ctx = useContext(AuthLoginInfo);
   const navigate = useNavigate()
    const [newCategoriesSubmitted,setNewCategoriesSubmitted] = useState(false);
    const [categoriesData,setCategoriesData] = useState([]);

    useEffect(()=>{
        setNewCategoriesSubmitted(false);
        axios
        .get("/categories",{withCredentials:true})
        .then((res)=>{
           if(res.data!=null){
           setCategoriesData(res.data)
           }
        })
    },[newCategoriesSubmitted])

    const handleDelete=(id)=>{
        if(window.confirm("Are you sure?"))
        axios
        .post("delete_category",{categoryId:id},{withCredentials:true})
        .then((res)=>{
            if(res.data==="success") {
                setNewCategoriesSubmitted(true)
            }
        })

    }


    const CategoriesTable= () => {
        const [currentPage, setCurrentPage] =useState(1)
        const itemsPerPage =30;
        const totalCategories = categoriesData.length;
        const computedCateroies = categoriesData.slice(
            (currentPage - 1) * itemsPerPage,
            (currentPage - 1) * itemsPerPage + itemsPerPage
          );
        const computedCategoryLength =computedCateroies.length;
        return (
          <>
          {" "}
          <div className="tableResultWrap">
            {" "}
            <div className="resultsSpan">
               Showing 
               <font className="resultsBold"> {computedCategoryLength} </font>
               of 
               <font className="resultsBold"> {totalCategories} </font>
               results
            </div>
            <Pagination
                total= {totalCategories}
                itemsPerPage = {itemsPerPage}
                currentPage = {currentPage}
                onPageChange = {(page)=> setCurrentPage(page)}
            />
          </div>
          <table>
            <thead>
                <tr>
                    <th>Category Id</th>
                    <th>Category Title</th>
                    <th>Logo</th>
                    <th>Position</th>
                    <th>Status</th>
                    <th>Actions</th>
                </tr>
            </thead>
            <tbody>
              {computedCateroies.map((category,i)=>{
               
                return (
                    <tr key={i}>
                        <td>
                            <font className="maincolor">#</font>
                            {category.id}
                            </td>
                            <td>{category.title}</td>
                            <td><img src={category.logo} className="w-10"/></td>
                            <td>{category.position}</td>
                            <td>{category.published===1 ?'🟢':'🔴'}</td>
                            <td>
                            <Link to={`/category/${category.id}`}>
                            <ReadMoreRounded/>
                            </Link>
                            <IconButton
                            onClick={()=>handleDelete(category.id)}>
                                <DeleteIcon/>
                            </IconButton>
                            
                            </td>
                    </tr>
                )
              })}
            </tbody>
          </table>

          </>
        )

    }


    return (
    <div className="bodyWrap">
        <div className="contentOrderWrap clientsTableWrap">
            <div className="leftSide">
                <div className="orderNavWrap">
                    <div className="addOrderWrap">
                        <button
                        className="addOrder"
                        onClick={()=>{
                        navigate(`/category/new`)
                        }}>
                            <AddCircleOutlineRounded/>
                            <span className="addOrderText">Add</span>

                        </button>
                    </div>
                </div>
                <div className="orderWrap">
                    <CategoriesTable/>
                </div>
            </div>
        </div>
    </div>);
}
export default Categories;